import classNames from 'classnames';

import { LinkList } from '@components/LinkList';

import { CmsHeroHeadline, CmsHomeHeadingLink } from '@interfaces/common';
import { breakpoints } from '@utils/device';
import { getHeadingTag, getNextHeadingLevel } from '@utils/heading';
import { getVisualSize } from '@utils/visual';

import styles from './HeroHeadline.module.css';

export interface HeroHeadlineProps
	extends Omit<CmsHeroHeadline, '_key' | '_type'> {
	headingLevel?: 1 | 2 | 3;
	headingFontSize?: 1 | 2;
	backgroundColor?: string;
	links?: CmsHomeHeadingLink[];
}

export const HeroHeadline: React.VFC<HeroHeadlineProps> = ({
	heading,
	headingLevel = 1,
	headingFontSize = 1,
	paragraph,
	tagline,
	backgroundColor,
	visual,
	links,
}) => {
	const TaglineTag = getHeadingTag(headingLevel);
	const nextHeadingLevel = getNextHeadingLevel(headingLevel);
	const HeadingTag = getHeadingTag(tagline ? nextHeadingLevel : headingLevel);

	const customProperties = {
		'--hero-headline-background-color':
			backgroundColor || `var(--color-core-white)`,
	} as React.CSSProperties;

	return (
		<div className={styles.headline} style={customProperties}>
			<div className={classNames(styles['content-wrapper'], 'stack-block')}>
				{tagline ? (
					<TaglineTag className="fluid-font-t6-bold">{tagline}</TaglineTag>
				) : null}
				<div
					className={classNames(
						styles['content-with-visual'],
						`fluid-font-t${headingFontSize}`
					)}
				>
					<HeadingTag className={styles.heading}>{heading}</HeadingTag>
					{visual ? (
						<img
							alt=""
							className={styles.visual}
							height={getVisualSize(visual).height}
							role="presentation"
							sizes={`${breakpoints.xxl} 15rem, (min-width: 55em) 10.4rem, 5.75rem`}
							src={`/img/${visual}/${visual}.png`}
							srcSet={`/img/${visual}/${visual}-120.webp 120w, /img/${visual}/${visual}-240.webp 240w, /img/${visual}/${visual}-480.webp 480w`}
							width={getVisualSize(visual).width}
						/>
					) : null}
				</div>
				{paragraph ? (
					<p className={classNames(styles.paragraph, 'fluid-font-t4')}>
						{paragraph}
					</p>
				) : null}
				{links ? <LinkList links={links} /> : null}
			</div>
		</div>
	);
};
