import Link from 'next/link';
import classNames from 'classnames';

import { HeroHeadline } from '@components/HeroHeadline';
import { Svg } from '@components/Svg';

import { CmsIngredientSection } from '@interfaces/common';
import { getNextHeadingLevel } from '@utils/heading';

import { IngredientList } from './IngredientList';
import styles from './IngredientSection.module.css';

export interface IngredientSectionProps
	extends Omit<CmsIngredientSection, '_key' | '_type'> {
	headingLevel?: 1 | 2 | 3;
	headingFontSize?: 1 | 2;
}

export const IngredientSection: React.VFC<IngredientSectionProps> = ({
	headingLevel = 1,
	headingFontSize = 1,
	headline,
	ingredients,
	ingredientsDesktopDisplay,
	link,
}) => {
	const nextHeadingLevel = getNextHeadingLevel(headingLevel);

	return (
		<section className={styles.section}>
			<HeroHeadline
				headingLevel={headingLevel}
				{...headline}
				headingFontSize={headingFontSize}
			/>
			<IngredientList
				desktopDisplay={ingredientsDesktopDisplay}
				headingLevel={nextHeadingLevel}
				ingredients={ingredients}
			/>
			{link ? (
				<div className={styles['link-wrapper']}>
					<Link href={link.url} passHref>
						<a
							className={classNames(styles.link, 'fluid-font-t6-bold')}
							href=""
						>
							{link.title} <Svg aria-hidden id="arrow-left" />
						</a>
					</Link>
				</div>
			) : null}
		</section>
	);
};
