import NextLink, { LinkProps as NextLinkProps } from 'next/link';

import { isInternalLink } from '@utils/link';

interface LinkProps extends NextLinkProps {
	className?: string;
	style?: React.CSSProperties;
}

export const Link: React.FC<LinkProps> = ({
	children,
	className,
	style,
	href,
}) => {
	if (isInternalLink(href)) {
		return (
			<NextLink href={href} passHref>
				<a className={className} href="-" style={style}>
					{children}
				</a>
			</NextLink>
		);
	}

	return (
		<a
			className={className}
			href={href.toString()}
			rel="noopener noreferrer"
			style={style}
			target="_blank"
		>
			{children}
		</a>
	);
};
