import { SanityImage } from '@components/SanityImage';

import { CmsImageSection } from '@interfaces/common';

import styles from './ImageSection.module.css';

export type ImageSectionProps = Omit<CmsImageSection, '_key' | '_type'>;

export const ImageSection: React.VFC<ImageSectionProps> = ({
	image,
	layout,
	imagePadding,
}) => {
	const imageOptions =
		layout === 'contained' ? { aspectRatio: 16 / 9 } : undefined;

	const customProperties = imagePadding
		? (imagePadding.reduce(
				(obj, key) => ({
					...obj,
					[`--image-section-padding-${key}`]: 'var(--spacing-lg)',
				}),
				{}
		  ) as React.CSSProperties)
		: undefined;

	return (
		<figure className={styles[layout]} style={customProperties}>
			<SanityImage
				className={styles.image}
				image={image}
				options={imageOptions}
			/>
		</figure>
	);
};
