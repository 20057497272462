import { RefObject, useEffect, useState } from 'react';

export const useMarquee = (
	marqueeElementRef: RefObject<HTMLDivElement> | null
): {
	isAnimated: boolean;
} => {
	const [isAnimated, setIsAnimated] = useState(false);
	const debounceWait = 500;
	let debounceTimeout: ReturnType<typeof setTimeout>;

	const updateMarquee = () => {
		const marqueeElement = marqueeElementRef?.current;

		if (marqueeElement && marqueeElement.firstChild) {
			// remove all children except the first child
			for (let i = 0; i < marqueeElement.children.length; i += 1) {
				const child = marqueeElement.children[i] as HTMLElement;

				if (child !== marqueeElement.firstChild) {
					marqueeElement.removeChild(child);
				}
			}

			// calculate how many clones are needed to fill the screen
			const { offsetWidth } = marqueeElement.firstChild as HTMLElement;
			const cloneCount = Math.ceil(window.innerWidth / offsetWidth);

			for (let i = 0; i < cloneCount; i += 1) {
				const clonedElement = marqueeElement.firstChild.cloneNode(
					true
				) as HTMLElement;

				clonedElement.setAttribute('aria-hidden', 'true');

				marqueeElement.appendChild(clonedElement);
			}

			// start animation again
			marqueeElement.style.removeProperty('--marquee-animation');
		}
	};

	const resizeObserverCallback = () => {
		const marqueeElement = marqueeElementRef?.current;

		if (marqueeElement) {
			if (marqueeElement.style.getPropertyValue('--marquee-animation') === '') {
				// reset animation as soon as resizing starts
				marqueeElement.style.setProperty('--marquee-animation', 'none');
			}

			clearTimeout(debounceTimeout);

			debounceTimeout = setTimeout(updateMarquee, debounceWait);
		}
	};

	useEffect(() => {
		const isResizeObserverSupported = 'ResizeObserver' in window;
		const marqueeElement = marqueeElementRef?.current;

		if (isResizeObserverSupported && marqueeElement) {
			setIsAnimated(true);

			const resizeObserver = new ResizeObserver(resizeObserverCallback);

			resizeObserver.observe(marqueeElement);

			return () => {
				resizeObserver.disconnect();

				clearTimeout(debounceTimeout);
			};
		}
	}, [marqueeElementRef]);

	return {
		isAnimated,
	};
};
