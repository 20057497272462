import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { Svg } from '@components/Svg';

import { carouselMessages } from '@data/ui-messages/carousel';
import { CarouselButtonProps, CarouselRefs } from '@interfaces/common';

import styles from './Carousel.module.css';

interface CarouselProps<T> extends CarouselButtonProps {
	renderItem: (item: T) => React.ReactNode;
	items: T[];
	refs: CarouselRefs;
}

export const Carousel: React.VFC<CarouselProps<any>> = ({
	renderItem,
	items,
	refs,
	scrollTo,
	isNextButtonDisabled,
	isPreviousButtonDisabled,
}) => {
	const intl = useIntl();
	const { scrollerRef, listRef } = refs;

	return (
		<div className={styles.carousel}>
			{/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
			<div className={styles.scroller} ref={scrollerRef} tabIndex={0}>
				<ul className={styles.list} ref={listRef}>
					{items.map((carouselItem) => (
						<li
							className={styles.item}
							key={carouselItem._key || carouselItem.slug}
						>
							{renderItem(carouselItem)}
						</li>
					))}
				</ul>
			</div>
			<button
				aria-label={intl.formatMessage(carouselMessages.previousLabel)}
				className={styles['nav-btn']}
				disabled={isPreviousButtonDisabled}
				onClick={() => {
					scrollTo('previousElementSibling');
				}}
				type="button"
			>
				<Svg id="arrow-left" />
			</button>
			<button
				aria-label={intl.formatMessage(carouselMessages.previousLabel)}
				className={classNames(styles['nav-btn'], styles.next)}
				disabled={isNextButtonDisabled}
				onClick={() => {
					scrollTo('nextElementSibling');
				}}
				type="button"
			>
				<Svg id="arrow-left" />
			</button>
		</div>
	);
};
