import { ReactNode } from 'react';
import classNames from 'classnames';

import { Link } from '@components/Link';
import { SanityImage } from '@components/SanityImage';
import { Svg } from '@components/Svg';

import { CmsSplitSection } from '@interfaces/common';
import { breakpoints } from '@utils/device';
import { getHeadingTag } from '@utils/heading';

import styles from './SplitSection.module.css';

export interface SplitSectionProps
	extends Omit<CmsSplitSection, '_key' | '_type'> {
	headingLevel?: 1 | 2;
	imageOverlayNode?: ReactNode;
	footerNode?: ReactNode;
}

export const SplitSection: React.VFC<SplitSectionProps> = ({
	heading,
	headingLevel = 2,
	subheading,
	paragraph,
	link,
	textColor,
	backgroundColor,
	image,
	imagePosition = {
		mobile: 'top',
		desktop: 'left',
	},
	imageOverlayNode,
	footerNode,
}) => {
	const HeadingTag = getHeadingTag(headingLevel);
	const customProperties = {
		'--split-section-color': textColor?.hex,
		'--split-section-background-color': backgroundColor?.hex,
	} as React.CSSProperties;

	return (
		<section
			className={classNames(
				styles.wrapper,
				styles[`image-align-v-${imagePosition.mobile}`],
				styles[`image-align-h-${imagePosition.desktop}`]
			)}
			style={customProperties}
		>
			<div className={styles['image-column']}>
				<div className={styles['image-wrapper']}>
					<SanityImage
						className={styles.image}
						image={image}
						sizes={`${breakpoints.lg} 50vw, 100vw`}
					/>
					{imageOverlayNode || null}
				</div>
			</div>
			<div className={classNames('stack-block', styles['text-column'])}>
				{subheading ? <p className="fluid-font-t5">{subheading}</p> : null}

				<HeadingTag className="fluid-font-t2">{heading}</HeadingTag>

				<p className="fluid-font-t5">{paragraph}</p>

				{link ? (
					<div className={styles['link-wrapper']}>
						<Link
							className={classNames(styles.link, 'fluid-font-t6-bold')}
							href={link.url}
						>
							{link.text} <Svg aria-hidden id="arrow-left" />
						</Link>
					</div>
				) : null}

				{footerNode || null}
			</div>
		</section>
	);
};
