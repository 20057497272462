import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { SanityImage } from '@components/SanityImage';

import { useMediaQuery } from '@hooks/useMediaQuery';
import {
	CmsIngredient,
	CmsIngredientsDesktopDisplay,
	HeadingLevel,
} from '@interfaces/common';
import { breakpoints } from '@utils/device';
import { getHeadingTag } from '@utils/heading';

import styles from './IngredientList.module.css';

export interface IngredientListProps {
	desktopDisplay: CmsIngredientsDesktopDisplay;
	headingLevel: HeadingLevel;
	ingredients: CmsIngredient[];
}

export const IngredientList: React.VFC<IngredientListProps> = ({
	desktopDisplay,
	headingLevel,
	ingredients,
}) => {
	const defaultTitleClassName = 'fluid-font-t6-bold';
	const defaultSubtitleClassName = 'fluid-font-t7';

	const [titleClassName, setTitleClassName] = useState(defaultTitleClassName);
	const [subtitleClassName, setSubtitleClassName] = useState(
		defaultSubtitleClassName
	);

	const HeadingTag = getHeadingTag(headingLevel);
	const isBreakpointLarge = useMediaQuery(breakpoints.lg);

	useEffect(() => {
		// As all our typography styles are bundled in classes we need to switch them out like this:
		if (isBreakpointLarge === true && desktopDisplay === 'grid') {
			setTitleClassName('fluid-font-t4-bold');
			setSubtitleClassName('fluid-font-t6');
		} else {
			setTitleClassName(defaultTitleClassName);
			setSubtitleClassName(defaultSubtitleClassName);
		}
	}, [isBreakpointLarge]);

	const sizes =
		desktopDisplay === 'grid'
			? `${breakpoints.lg} 25vw, 50vw`
			: `${breakpoints.lg} ${100 / ingredients.length}vw, 50vw`;

	return (
		<ul className={classNames(styles.list, styles[desktopDisplay])}>
			{ingredients.map(({ _key, title, subtitle, image }) => {
				return (
					<li key={_key}>
						<article>
							<SanityImage
								className={classNames(styles.image, {
									[styles.rounded]: !image.noBorderRadius,
								})}
								image={image}
								options={{
									fit: 'crop',
								}}
								sizes={sizes}
							/>
							<header className={styles.header}>
								<HeadingTag
									className={classNames(styles.title, titleClassName)}
								>
									{title}
								</HeadingTag>
								<p className={classNames(styles.subtitle, subtitleClassName)}>
									{subtitle}
								</p>
							</header>
						</article>
					</li>
				);
			})}
		</ul>
	);
};
