import { useRef } from 'react';
import classNames from 'classnames';

import { SanityImage } from '@components/SanityImage';

import { useMarquee } from '@hooks/useMarquee';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { CmsImage } from '@interfaces/media';
import { breakpoints, isDesktop } from '@utils/device';

import styles from './LogoMarquee.module.css';

export interface LogoMarqueeProps {
	title: string;
	logos: CmsImage[];
	showTitle?: boolean;
	bgColor?: string;
	size?: 'medium' | 'large';
}

export const LogoMarquee: React.VFC<LogoMarqueeProps> = ({
	bgColor = 'var(--color-core-white)',
	title,
	logos,
	size = 'medium',
	showTitle = false,
}) => {
	// Corresponds with value of --marquee-logo-size
	const MARQUEE_LOGO_SIZE = isDesktop() ? 80 : 64;
	const marqueeElementRef = useRef<HTMLDivElement>(null);
	const isBreakpointLarge = useMediaQuery(breakpoints.lg);

	const { isAnimated } = useMarquee(marqueeElementRef);

	const totalLogoWidth = logos.reduce((accumulator, { height, width }) => {
		return accumulator + MARQUEE_LOGO_SIZE * (width / height);
	}, 0);

	const wrapperStyle = {
		'--marquee-background-color': bgColor,
	} as React.CSSProperties;

	const marqueeStyle = {
		'--marquee-block-padding':
			size === 'large'
				? 'var(--marquee-block-padding-large)'
				: 'var(--marquee-block-padding-medium)',
	} as React.CSSProperties;

	const animatedMarqueeStyle = {
		'--marquee-total-logo-width': totalLogoWidth,
		...marqueeStyle,
	} as React.CSSProperties;

	return (
		<section
			aria-label={title}
			className={classNames(styles.wrapper, {
				[styles['title-padding']]: showTitle,
			})}
			style={wrapperStyle}
		>
			{showTitle ? (
				<h2 className={classNames(styles.title, 'fluid-font-t5-bold')}>
					{title}
				</h2>
			) : null}

			<div
				className={classNames(styles.marquee, {
					[styles['is-animated']]: isAnimated,
				})}
				ref={marqueeElementRef}
				style={isAnimated ? animatedMarqueeStyle : marqueeStyle}
			>
				<div className={styles.logos}>
					{logos.map((logo) => {
						const logoStyle = {
							'--marquee-logo-aspect-ratio': logo.width / logo.height,
						} as React.CSSProperties;

						return (
							<SanityImage
								className={styles.logo}
								image={logo}
								key={logo.id}
								loading={isBreakpointLarge ? 'lazy' : 'eager'}
								style={logoStyle}
							/>
						);
					})}
				</div>
			</div>
		</section>
	);
};
