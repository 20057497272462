// returns the height and width of images in /public/img directory
export const getVisualSize = (visual: string) => {
	switch (visual) {
		case 'grass-smiley':
			return { height: 480, width: 480 };
		case 'metal-smiley':
			return { height: 480, width: 420 };
		case 'plant':
			return { height: 480, width: 497 };
		case 'grass':
			return { height: 480, width: 480 };
		case 'cow':
			return { height: 480, width: 560 };
		case 'burger':
			return { height: 480, width: 560 };
		case 'cow-side':
			return { height: 320, width: 191 };
		case 'metal-smiley-green':
			return { height: 480, width: 495 };
		default:
			return { height: 100, width: 100 };
	}
};
