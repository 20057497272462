import { UrlObject } from 'url';

export const hasAnchor = (href: string) => {
	return href.indexOf('#') !== -1;
};

export const isInternalLink = (url: string | UrlObject) => {
	if (typeof url === 'string') {
		return url.startsWith('/');
	}

	return !url.host;
};
