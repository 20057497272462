import React from 'react';
import classNames from 'classnames';

import { SanityImage } from '@components/SanityImage';

import { CmsCarouselItem } from '@interfaces/common';
import { breakpoints } from '@utils/device';

import styles from './CarouselItem.module.css';

export const CarouselItem: React.VFC<CmsCarouselItem> = ({
	image,
	title,
	subtitle,
	subtitleColor,
	text,
}) => {
	const customProperties = {
		'--carousel-item-subtitle-color':
			subtitleColor || 'var(--color-core-dark-grey)',
	} as React.CSSProperties;

	return (
		<div
			className={classNames(styles.item, 'stack-block')}
			style={customProperties}
		>
			<div className={styles['image-wrapper']}>
				<SanityImage
					className={styles.image}
					image={image}
					sizes={`${breakpoints.md} 40vw, ${breakpoints.lg} 25vw, 80vw`}
				/>
			</div>

			<h3 className={classNames(styles.title, 'fluid-font-t4-bold')}>
				{title}
			</h3>

			{subtitle ? <p className={styles.subtitle}>{subtitle}</p> : null}

			<p>{text}</p>
		</div>
	);
};
