import classNames from 'classnames';

import { Link } from '@components/Link';

import { CmsLinkRecord } from '@interfaces/common';

import styles from './LinkButton.module.css';

interface LinkButtonProps extends CmsLinkRecord {
	className?: string;
}

export const LinkButton: React.VFC<LinkButtonProps> = ({
	url,
	text,
	className: classes,
}) => {
	return (
		<Link
			className={classNames(
				classes,
				styles['link-button'],
				'fluid-font-t6-bold'
			)}
			data-testid="LinkButton"
			href={url}
		>
			{text}
		</Link>
	);
};
