import { useRef } from 'react';
import classNames from 'classnames';

import { CarouselItem } from '@components/CarouselSection';
import { LinkButton } from '@components/LinkButton';

import { useCarousel } from '@hooks/useCarousel';
import { CmsCarouselSection, CmsLinkRecord } from '@interfaces/common';

import { Carousel } from './Carousel';
import styles from './CarouselSection.module.css';

export interface CarouselSectionProps
	extends Omit<CmsCarouselSection, '_key' | '_type' | 'image'> {
	link?: CmsLinkRecord;
	renderItem?: (item: any) => React.ReactNode;
}

export const CarouselSection: React.VFC<CarouselSectionProps> = ({
	textColor,
	backgroundColor,
	heading,
	description,
	carousel,
	link,
	renderItem = CarouselItem,
}) => {
	const scrollerRef = useRef<HTMLDivElement>(null);
	const listRef = useRef<HTMLUListElement>(null);

	const carouselRefs = { scrollerRef, listRef };

	const carouselButtonProps = useCarousel(carouselRefs);

	const customProperties = {
		'--carousel-section-color': textColor || 'var(--color-core-black)',
		'--carousel-section-background-color':
			backgroundColor?.hex || 'var(--color-core-white)',
	} as React.CSSProperties;

	return (
		<section className={styles['carousel-section']} style={customProperties}>
			<header className={classNames(styles.header, 'stack-block')}>
				<h2 className="fluid-font-t2">{heading}</h2>
				{description ? <p>{description}</p> : null}
			</header>

			<Carousel
				items={carousel}
				refs={carouselRefs}
				renderItem={renderItem}
				{...carouselButtonProps}
			/>

			{link ? (
				<footer className={styles.footer}>
					<LinkButton {...link} />
				</footer>
			) : null}
		</section>
	);
};
