import { HeadingLevel } from '@interfaces/common';

// For creating dynamic headings that plays well with TypeScript
export const getHeadingTag = (headingLevel: HeadingLevel) => {
	switch (headingLevel) {
		default:
			return 'h1';
		case 2:
			return 'h2';
		case 3:
			return 'h3';
		case 4:
			return 'h4';
		case 5:
			return 'h5';
		case 6:
			return 'h6';
	}
};

export const getNextHeadingLevel = (headingLevel: HeadingLevel) => {
	switch (headingLevel) {
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		default:
			return 6;
	}
};
