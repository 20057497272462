import classNames from 'classnames';

import { LinkButton } from '@components/LinkButton';

import { CmsHomeHeadingLink } from '@interfaces/common';

import styles from './LinkList.module.css';

interface LinkListProps {
	links: CmsHomeHeadingLink[];
}

export const LinkList: React.VFC<LinkListProps> = ({ links }) => {
	return (
		<ul className={classNames(styles.links)}>
			{links.map(({ linkText, linkUrl, backgroundColor, textColor }) => {
				const customProperties = {
					'--background-color': backgroundColor,
					'--text-color': textColor,
				} as React.CSSProperties;

				return (
					<li key={linkText} style={customProperties}>
						<LinkButton className={styles.link} text={linkText} url={linkUrl} />
					</li>
				);
			})}
		</ul>
	);
};
