import { useEffect, useState } from 'react';

import { useMediaQuery } from '@hooks/useMediaQuery';
import {
	CarouselButtonProps,
	CarouselRefs,
	CarouselSibling,
} from '@interfaces/common';

export const useCarousel = (refs: CarouselRefs): CarouselButtonProps => {
	const [isPreviousButtonDisabled, setIsPreviousButtonDisabled] =
		useState(true);
	const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

	const { scrollerRef, listRef } = refs;

	const isReducedMotionPreferred = useMediaQuery(
		'(prefers-reduced-motion: reduce)'
	);

	const scrollTo = (sibling: CarouselSibling) => {
		const scrollerElement = scrollerRef?.current;
		const listElement = listRef?.current;
		if (scrollerElement && listElement) {
			const visibleElements = listElement.querySelectorAll(
				'[data-visible="true"]'
			);

			if (visibleElements.length) {
				const siblingElement = visibleElements[0][sibling] as HTMLElement;

				// we need to take the inline scroll-padding into account for the new scroll position
				const computedStyles = window.getComputedStyle(scrollerElement);
				const scrollPaddingLeft = parseFloat(
					computedStyles.getPropertyValue('scroll-padding-left')
				);

				scrollerElement.scrollTo({
					left: siblingElement.offsetLeft - scrollPaddingLeft,
					behavior: isReducedMotionPreferred ? 'auto' : 'smooth',
				});
			}
		}
	};

	const intersectionObserverCallback = (
		entries: IntersectionObserverEntry[]
	) => {
		entries.forEach(({ target, isIntersecting }) => {
			const targetElement = target as HTMLElement;

			targetElement.dataset.visible = isIntersecting ? 'true' : 'false';

			if (targetElement.parentElement?.firstChild === targetElement) {
				setIsPreviousButtonDisabled(isIntersecting);
			}

			if (targetElement.parentElement?.lastChild === targetElement) {
				setIsNextButtonDisabled(isIntersecting);
			}
		});
	};

	const resizeObserverCallback = (entries: ResizeObserverEntry[]) => {
		// reset scroll position as snapping after layout changes is only properly supported in chrome
		// https://web.dev/snap-after-layout/#interoperability
		entries.forEach(({ target }) => {
			const targetElement = target as HTMLElement;

			targetElement.scrollLeft = 0;
		});
	};

	useEffect(() => {
		const listElement = listRef?.current;
		const isIntersectionObserverSupported = 'IntersectionObserver' in window;

		if (isIntersectionObserverSupported && listElement) {
			const intersectionObserver = new IntersectionObserver(
				intersectionObserverCallback,
				{ rootMargin: '9999px 0px', threshold: 0.9 }
			);

			for (let i = 0; i < listElement.children.length; i += 1) {
				intersectionObserver.observe(listElement.children[i]);
			}

			return () => {
				intersectionObserver.disconnect();
			};
		}
	}, [listRef]);

	useEffect(() => {
		const scrollerElement = scrollerRef?.current;
		const isResizeObserverSupported = 'ResizeObserver' in window;

		if (isResizeObserverSupported && scrollerElement) {
			const resizeObserver = new ResizeObserver(resizeObserverCallback);

			resizeObserver.observe(scrollerElement);

			return () => {
				resizeObserver.disconnect();
			};
		}
	}, [scrollerRef]);

	return { scrollTo, isPreviousButtonDisabled, isNextButtonDisabled };
};
