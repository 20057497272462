export const carouselMessages = {
	previousLabel: {
		id: 'previousLabel',
		defaultMessage: 'Previous slide',
	},
	nextLabel: {
		id: 'nextLabel',
		defaultMessage: 'Next slide',
	},
};
